export const answers = {
  yes_no: [
    { id: 0, name: 'No', value: 0 },
    { id: 1, name: 'Yes', value: 1 },
  ],
  project_interested: ['Green field', 'Newly started', 'Platform improvement'],
  team_size: ['Large team', 'Small team', 'Team of one'],
  proficiency_level: ['Basic', 'Intermediate', 'Advanced', 'Expert'],
  competency: [
    'Frontend',
    'Backend',
    'Fullstack (BE-heavy)',
    'Fullstack (FE-heavy)',
    'Fullstack',
    'Mobile',
    'Design',
    'QA',
    'DevOps',
    'Data Science',
    'Data Engineering',
    'Machine Learning',
    'Game Development',
    'Business Intelligence',
    'Embedded Engineer',
  ],
  compensation_plans: [
    { name: 'Hourly', value: 'hourly' },
    { name: 'Monthly', value: 'monthly' },
  ],
  commitment_type: ['Full-time', 'Part-time', 'As needed'],
  english_level: [
    { id: 1, name: 'Beginner', value: 1 },
    { id: 2, name: 'Intermediate', value: 2 },
    { id: 3, name: 'Advanced', value: 3 },
    { id: 4, name: 'Fluent', value: 4 },
  ],
  first_start: [
    'Immediately',
    'In one week',
    'In two weeks',
    'In one month',
    'In more than 1 month',
  ],
  rejected: [
    { value: 0, name: 'Active' },
    { value: 1, name: 'Rejected' },
    { value: null, name: 'All' },
  ],
  interview_stages: [
    {
      value: 3,
      name: 'Screening',
    },
    {
      value: 5,
      name: 'Technical Interview',
    },
  ],
  interview_status: [
    { name: 'Invited', value: 'invited' },
    { name: 'Scheduled', value: 'scheduled' },
    { name: 'Pending', value: 'pending' },
    { name: 'Completed', value: 'completed' },
    { name: 'Rescheduled', value: 'rescheduled' },
    { name: 'Cancelled', value: 'cancelled' },
    { name: 'Cancelled: No show', value: 'no_show' },
  ],
  experience: [
    ...new Set(
      [...Array(588).keys()].map((index) => {
        return index < 24
          ? `${index} month${(index > 1 && 's') || ''}`
          : `${Math.ceil(index / 12)} years`;
      })
    ),
  ].map((index) => {
    return {
      name: index,
      value:
        index.split(' ')[1] === 'years'
          ? parseInt(index.split(' ')[0], 10) * 12
          : parseInt(index.split(' ')[0], 10),
    };
  }),
  interview: [
    { name: 'Filled', value: 'filled' },
    { name: 'Not Filled', value: 'not_filled' },
  ],
  interview_recommendation: [
    { name: 'Fast-Track', value: 'fast-track' },
    { name: 'Proceed', value: 'proceed' },
    { name: 'Needs Review', value: 'needs_review' },
    { name: 'Do not Proceed', value: 'do_not_proceed' },
  ],
  benchmark: [
    { value: 'high', name: 'High' },
    { value: 'typical', name: 'Typical' },
    { value: 'low', name: 'Low' },
  ],
  codility_statuses: [
    {
      value: 'scheduled',
      name: 'Scheduled',
    },
    {
      value: 'invited',
      name: 'Invited',
    },
    {
      value: 'completed',
      name: 'Completed',
    },
    {
      value: 'passed',
      name: 'Passed',
    },
    {
      value: 'failed',
      name: 'Failed',
    },
  ],
  demand_level: [
    { name: 'High Demand', value: 'high' },
    { name: 'Moderate Demand', value: 'moderate' },
    { name: 'Oversupply', value: 'oversupply' },
  ],
  regions: [
    { name: 'Americas', value: 'americas' },
    { name: 'EMEA', value: 'emea' },
  ],
};

const arrayToAnswerType = (array, { stringify } = {}) => {
  return [
    { value: 'empty', name: 'Empty' },
    ...array.map((item) => ({
      value: item,
      name: stringify ? JSON.stringify(item) : item,
    })),
  ];
};

const objectArrayToAnswerType = (array) => {
  return [
    { value: 'empty', name: 'Empty' },
    ...array.map(({ id, value, name }) => ({
      id,
      value,
      name,
    })),
  ];
};

const numericRelations = [11, 1, 3, 12, 6, 13];
const listRelations = [2, 4, 5];
const dateRelations = [11, 12, 6, 7, 8, 13];
const customBooleanRelations = [9, 10];
const textRelations = [2, 4, 9];

export const variables = [
  {
    name: 'Able to start full-time',
    value: 'start_full_time',
    possible_relations: listRelations,
    type: 'list',
    answer_type: arrayToAnswerType(answers.first_start),
  },
  {
    name: 'Able to start part-time',
    value: 'start_part_time',
    possible_relations: listRelations,
    type: 'list',
    answer_type: arrayToAnswerType(answers.first_start),
  },
  {
    name: 'Applicant stars rating',
    value: 'application_stars',
    possible_relations: numericRelations,
    type: 'numeric',
    single_value: 'empty',
    range: [1, 5],
  },
  {
    name: 'Applicant timezone',
    value: 'applicant_timezone',
    possible_relations: listRelations,
    type: 'list',
    answer_type: 'applicant_timezone',
  },
  {
    name: 'Applicant timezone offset',
    value: 'applicant_timezone_offset',
    possible_relations: numericRelations,
    type: 'numeric',
    single_value: 'empty',
  },
  {
    name: 'Applied at',
    value: 'applied_at',
    possible_relations: dateRelations,
    type: 'date',
    single_value: 'empty',
    answer_type: 'date',
  },
  {
    name: 'Assignee recruiter',
    value: 'recruiter',
    possible_relations: listRelations,
    type: 'list',
    answer_type: 'recruiter',
  },
  {
    name: 'Assignee sourcer',
    value: 'sourcer',
    possible_relations: listRelations,
    type: 'list',
    answer_type: 'sourcer',
  },
  {
    name: 'City',
    value: 'city',
    possible_relations: textRelations,
    type: 'text',
    single_value: 'empty',
  },
  {
    name: 'Codility score',
    value: 'codility_score',
    possible_relations: numericRelations,
    type: 'numeric',
    single_value: 'empty',
    range: [0, 100],
  },
  {
    name: 'Codility status',
    value: 'codility_status',
    possible_relations: listRelations,
    type: 'list',
    answer_type: objectArrayToAnswerType(answers.codility_statuses),
  },
  {
    name: 'Codility test',
    value: 'codility_test',
    possible_relations: listRelations,
    type: 'list',
    answer_type: 'codility_test',
  },
  {
    name: 'Commercial experience benchmark',
    value: 'comm_exp_benchmark',
    possible_relations: listRelations,
    type: 'list',
    answer_type: objectArrayToAnswerType(answers.benchmark),
  },
  {
    name: 'Commercial experience years',
    value: 'comm_exp_years',
    possible_relations: numericRelations,
    type: 'numeric',
    single_value: 'empty',
  },
  {
    name: 'Commitment type',
    value: 'commitment',
    possible_relations: listRelations,
    type: 'list',
    answer_type: arrayToAnswerType(answers.commitment_type),
  },
  {
    name: 'Compensation plan',
    value: 'compensation_plan',
    possible_relations: listRelations,
    type: 'list',
    answer_type: answers.compensation_plans,
  },
  {
    name: 'Competency',
    value: 'competency',
    possible_relations: listRelations,
    type: 'list',
    answer_type: arrayToAnswerType(answers.competency),
  },
  {
    name: 'Country',
    value: 'country',
    possible_relations: listRelations,
    type: 'list',
    answer_type: 'countries',
  },
  {
    name: 'Created at',
    value: 'created_at',
    possible_relations: dateRelations,
    type: 'date',
    single_value: 'empty',
    answer_type: 'date',
  },
  {
    name: 'Demand level',
    value: 'demand',
    possible_relations: listRelations,
    type: 'list',
    answer_type: objectArrayToAnswerType(answers.demand_level),
  },
  {
    name: 'Desired test date',
    value: 'desired_test_date',
    possible_relations: dateRelations,
    type: 'date',
    single_value: 'empty',
    answer_type: 'date',
  },
  {
    name: 'English level',
    value: 'english_level',
    possible_relations: listRelations,
    type: 'list',
    answer_type: objectArrayToAnswerType(answers.english_level),
  },
  {
    name: 'Fast-tracked',
    value: 'fast_tracked',
    possible_relations: customBooleanRelations,
    type: 'list',
    single_value: 'true',
  },
  {
    name: 'Final grade',
    value: 'final_grade',
    possible_relations: numericRelations,
    type: 'numeric',
    single_value: 'empty',
    range: [0, 100],
  },
  {
    name: 'Freelance experience years',
    value: 'free_exp_years',
    possible_relations: numericRelations,
    type: 'numeric',
    single_value: 'empty',
  },
  {
    name: 'Hourly rate',
    value: 'hourly_rate',
    possible_relations: numericRelations,
    type: 'numeric',
    single_value: 'empty',
  },
  {
    name: 'Industry experience',
    value: 'industry_experience',
    possible_relations: listRelations,
    type: 'list',
    answer_type: 'industries',
  },
  {
    name: 'Industry preferences',
    value: 'industry_preferences',
    possible_relations: listRelations,
    type: 'list',
    answer_type: 'industries',
  },
  {
    name: 'Interview stage',
    value: 'interview_stage',
    possible_relations: listRelations,
    type: 'list',
    answer_type: objectArrayToAnswerType(answers.interview_stages),
  },
  {
    name: 'Interview recommendation',
    value: 'interview_recommendation',
    possible_relations: listRelations,
    type: 'list',
    answer_type: objectArrayToAnswerType(answers.interview_recommendation),
  },
  {
    name: 'Interview stars rating',
    value: 'interview_stars',
    possible_relations: numericRelations,
    type: 'numeric',
    single_value: 'empty',
    range: [1, 5],
  },
  {
    name: 'Interview status',
    value: 'interview_status',
    possible_relations: listRelations,
    type: 'list',
    answer_type: objectArrayToAnswerType(answers.interview_status),
  },
  {
    name: 'Joined date',
    value: 'hired_date',
    possible_relations: dateRelations,
    type: 'date',
    single_value: 'empty',
    answer_type: 'date',
  },
  {
    name: 'Last incoming e-mail',
    value: 'last_incoming_email',
    possible_relations: dateRelations,
    type: 'date',
    single_value: 'empty',
    answer_type: 'date',
  },
  {
    name: 'Last outgoing e-mail',
    value: 'last_outgoing_email',
    possible_relations: dateRelations,
    type: 'date',
    single_value: 'empty',
    answer_type: 'date',
  },
  {
    name: 'Monthly rate',
    value: 'monthly_rate',
    possible_relations: numericRelations,
    type: 'numeric',
    single_value: 'empty',
  },
  {
    name: 'Offer date',
    value: 'offer_date',
    possible_relations: dateRelations,
    type: 'date',
    single_value: 'empty',
    answer_type: 'date',
  },
  {
    name: 'Preferred team size',
    value: 'preferred_team_size',
    possible_relations: listRelations,
    type: 'list',
    answer_type: arrayToAnswerType(answers.team_size),
  },
  {
    name: 'Primary skill',
    value: 'primary_skill',
    possible_relations: listRelations,
    type: 'list',
    answer_type: 'skills',
  },
  {
    name: 'Primary skill benchmark',
    value: 'primary_skill_benchmark',
    possible_relations: listRelations,
    type: 'list',
    answer_type: objectArrayToAnswerType(answers.benchmark),
  },
  {
    name: 'Projects interested in',
    value: 'project_interested',
    possible_relations: listRelations,
    type: 'list',
    answer_type: arrayToAnswerType(answers.project_interested),
  },
  {
    name: 'Rate benchmark',
    value: 'rate_benchmark',
    possible_relations: listRelations,
    type: 'list',
    answer_type: objectArrayToAnswerType(answers.benchmark),
  },
  {
    name: 'Region',
    value: 'region',
    possible_relations: listRelations,
    type: 'list',
    answer_type: objectArrayToAnswerType(answers.regions),
  },
  {
    name: 'Rejected by',
    value: 'rejected_by',
    possible_relations: listRelations,
    type: 'list',
    answer_type: 'users',
  },
  {
    name: 'Rejection date',
    value: 'rejected_at',
    possible_relations: dateRelations,
    type: 'date',
    single_value: 'empty',
    answer_type: 'date',
  },
  {
    name: 'Rejection reason',
    value: 'rejection_reason',
    possible_relations: listRelations,
    type: 'list',
    answer_type: 'rejection_reasons',
  },
  {
    name: 'Scorecard fill date',
    value: 'scorecard_fill_date',
    possible_relations: dateRelations,
    type: 'date',
    single_value: 'empty',
    answer_type: 'date',
  },
  {
    name: 'Scheduled email date',
    value: 'scheduled_email_date',
    possible_relations: dateRelations,
    type: 'date',
    single_value: 'empty',
    answer_type: 'date',
  },
  {
    name: 'Screening selection probability',
    value: 'hiring_probability',
    possible_relations: numericRelations,
    type: 'numeric',
    single_value: 'empty',
    range: [0, 100],
  },
  {
    name: 'Screening call date',
    value: 'screening_date',
    possible_relations: dateRelations,
    type: 'date',
    single_value: 'empty',
    answer_type: 'date',
  },
  {
    name: 'Screening call scorecard',
    value: 'screening_interview',
    possible_relations: listRelations,
    type: 'list',
    answer_type: objectArrayToAnswerType(answers.interview),
  },
  {
    name: 'Skills',
    value: 'skills',
    possible_relations: listRelations,
    type: 'list',
    answer_type: 'skills',
  },
  {
    name: 'Source',
    value: 'source',
    possible_relations: listRelations,
    type: 'list',
    answer_type: 'source',
  },
  {
    name: 'Stage',
    value: 'stage',
    possible_relations: listRelations,
    type: 'list',
    answer_type: 'stages',
  },
  {
    name: 'Technical interview date',
    value: 'tech_interview_date',
    possible_relations: dateRelations,
    type: 'date',
    single_value: 'empty',
    answer_type: 'date',
  },
  {
    name: 'Technical interview scorecard',
    value: 'tech_interview',
    possible_relations: listRelations,
    type: 'list',
    answer_type: objectArrayToAnswerType(answers.interview),
  },
  {
    name: 'Technical interviewer',
    value: 'tech_interviewer',
    possible_relations: listRelations,
    type: 'list',
    answer_type: 'tech_interviewer',
  },
  {
    name: 'Time-to-Membership',
    value: 'time_to_membership',
    possible_relations: numericRelations,
    type: 'numeric',
    single_value: 'empty',
  },
  {
    name: 'Urgency',
    value: 'urgency',
    possible_relations: listRelations,
    type: 'list',
    answer_type: [
      {
        value: 'critical',
        name: 'Critical',
      },
      {
        value: 'high',
        name: 'High',
      },
      {
        value: 'not_urgent',
        name: 'Not urgent',
      },
    ],
  },
];

export const relations = [
  {
    id: 1,
    name: 'is greater than',
    value: 'gte',
    allow_multiple: false,
  },
  {
    id: 2,
    name: 'is',
    value: 'is',
    allow_multiple: true,
  },
  {
    id: 3,
    name: 'is less than',
    value: 'lte',
    allow_multiple: false,
  },
  {
    id: 4,
    name: 'is not',
    value: 'is_not',
    allow_multiple: true,
  },
  {
    id: 5,
    name: 'any of',
    value: 'any_of',
    allow_multiple: true,
  },
  {
    id: 6,
    name: 'between',
    value: 'between',
    allow_multiple: true,
  },
  {
    id: 7,
    name: 'earlier than',
    value: 'lte',
    allow_multiple: false,
  },
  {
    id: 8,
    name: 'later than',
    value: 'gte',
    allow_multiple: false,
  },
  {
    id: 9,
    name: 'is {value}',
    value: 'is',
    allow_multiple: false,
    single_value: true,
  },
  {
    id: 10,
    name: 'is not {value}',
    value: 'is_not',
    allow_multiple: false,
    single_value: true,
  },
  {
    id: 11,
    name: 'is',
    value: 'eq',
    allow_multiple: false,
  },
  {
    id: 12,
    name: 'is not',
    value: 'not_eq',
  },
  {
    id: 13,
    name: 'is {value}',
    value: 'eq',
    allow_multiple: false,
    single_value: true,
  },
  {
    id: 14,
    name: 'is not {value}',
    value: 'eq_not',
    allow_multiple: false,
    single_value: true,
  },
];
